import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver-es';
import { debounce } from 'lodash-es';
import { first } from 'rxjs';
import Common from 'src/app/_helpers/common';
import { PayrollRecent } from 'src/app/_models/_payroll/payroll-recent';
import { Paginator } from 'src/app/_models/common/paginator';
import { SortData } from 'src/app/_models/common/sortData';
import { TableType } from 'src/app/_models/enums/tableType';
import { PayrollService, ToastrTranslateService } from 'src/app/_services';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';
import { LocalStorageService } from '../../_services/local.storage.service';
import { AddPayrollCommentComponent } from '../add-payroll-comment/add-payroll-comment.component';
import { PayCalculationConfirmDialogComponent } from '../pay-calculation-confirm-dialog/pay-calculation-confirm-dialog.component';
import { PayrollPdfPreviewPopupComponent } from '../payroll-pdf-preview-popup/payroll-pdf-preview-popup.component';

@Component({
  selector: 'app-recent-payrolls',
  templateUrl: './recent-payrolls.component.html',
  styleUrls: ['./recent-payrolls.component.scss'],
})
export class RecentPayrollsComponent implements OnInit, AfterViewInit {
  periodForm: UntypedFormGroup;
  dataSource = new MatTableDataSource<PayrollRecent>();
  totalSize = 0;
  pageSizeOptions: number[];
  pageSize;
  currentPage;
  sortData: SortData;
  loading = false;
  startDate = new Date();
  endDate = Common.getDateEndOfDay(new Date());
  recentPayrolls: PayrollRecent[] = [];

  public displayedColumns: string[] = [
    'driver',
    'truck',
    'payrollDateFrom',
    'payrollDateTo',
    'payrollAmount',
    'paid',
    'paidBy',
    'reportPreview',
    'comment',
    'markAsPaid',
  ];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    private payrollService: PayrollService,
    private localStorageSortService: LocalStorageSortService,
    private localStorageService: LocalStorageService,
    private toastrTranslateService: ToastrTranslateService
  ) {}
  ngOnInit(): void {
    this.startDate.setMonth(this.startDate.getMonth() - 1);
    this.periodForm = new UntypedFormGroup({
      start: new UntypedFormControl(null),
      end: new UntypedFormControl(null),
    });
    this.sortData = this.localStorageSortService.getSortData(
      TableType.PayrollRecent
    );
    this.initPaginatorOptions();

    this.onDateChange = debounce(this.onDateChange, 1000);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.refresh();
      this.currentPage = 0;
    });
    setTimeout(() => {
      this.sort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.sort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.PayrollRecent,
        this.sort
      );
      return item[property];
    };
  }

  onComment(payroll) {
    const dialogRef = this.dialog.open(AddPayrollCommentComponent, {
      width: '450px',
      data: { comment: payroll.note },
    });
    dialogRef.afterClosed().subscribe((comment) => {
      if (comment !== false) {
        this.payrollService
          .setPayrollCalculationComment(payroll.id, comment)
          .subscribe((res) => {
            payroll.note = comment;
            this.toastrTranslateService.success('PAYROLL.COMMENT_SAVED');
          });
      }
    });
  }

  async onPreview(payrollId: number) {
    try {
      let data = await this.payrollService.getPdfForPayrollId(payrollId);
      var file = new Blob([data], { type: 'application/pdf' });
      const dialogRef = this.dialog.open(PayrollPdfPreviewPopupComponent, {
        width: '1024px',
        data: file,
      });
    } catch (error) {
      this.toastrTranslateService.error(error, 'COMMON.ERROR_DATA');
    }
  }

  markPaid(payroll: PayrollRecent, isPaid: boolean) {
    const dialogRef = this.dialog.open(PayCalculationConfirmDialogComponent, {
      disableClose: true,
      position: { top: '20vh' },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.payrollService.payrollSwitchPaid(payroll.id, isPaid).subscribe({
          next: () => {
            if (isPaid) {
              this.toastrTranslateService.success('PAYROLL.PAID_SUCCESS');
            } else {
              this.toastrTranslateService.success('PAYROLL.UNPAID_SUCCESS');
            }
            this.refresh();
          },
          error: (res) => {
            this.toastrTranslateService.error(res, 'PAYROLL.ERROR');
          },
        });
      }
    });
  }

  onDateChange() {
    this.refresh();
  }

  refresh() {
    this.loadRecentPayrolls();
  }

  async exportCsv() {
    try {
      this.loading = true;
      const data = await this.payrollService.getCsv(
        this.startDate,
        this.endDate,
        this.getPaginator()
      );
      const blob = new Blob([data], {
        type: 'application/vnd.ms-excel',
      });

      const fileName =
        'Recently Closed Payrolls ' +
        this.startDate.toLocaleDateString() +
        '-' +
        this.endDate.toLocaleDateString() +
        '.xlsx';
      saveAs(blob, fileName);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.toastrTranslateService.error(error, 'COMMON.ERROR_DATA');
    }
  }

  loadRecentPayrolls() {
    this.payrollService
      .getRecentPayrollList(this.startDate, this.endDate, this.getPaginator())
      .pipe(first())
      .subscribe((response) => {
        if (response) {
          this.dataSource.data = response.collection;
          setTimeout(() => {
            this.paginator.length = response.count;
            this.paginator.pageIndex = this.currentPage;
          });
        }
      });
  }

  private getPaginator(): Paginator {
    const paginator = new Paginator();
    paginator.pageSize = this.pageSize;
    paginator.pageNumber = this.currentPage + 1;
    paginator.sortBy = this.sort?.active;
    paginator.sortOrder = this.sort?.direction;
    this.localStorageSortService.setSortData(
      TableType.PayrollRecent,
      this.sort
    );
    return paginator;
  }

  public onChangePage(event?: PageEvent): PageEvent {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.localStorageService.setPagginatorPageSize(event.pageSize);
    this.loadRecentPayrolls();
    return event;
  }

  initPaginatorOptions() {
    this.pageSize = this.localStorageService.getPagginatorPageSize();
    this.pageSizeOptions = [10, 25, 50, 100];
    this.currentPage = 0;
  }
}
