<div class="wrapperTable" id="wrapperManageTables">
  <div class="add-new">
    <button mat-icon-button class="refresh" (click)="refresh()">
      <mat-icon
        matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
        matTooltipPosition="below"
        >refresh</mat-icon
      >
    </button>
    <button
      mat-flat-button
      class="btnDialog button-accounting add-new-button"
      matTooltip="{{ 'ACCOUNTING.ADD_NEW_ACCOUNTING' | translate }}"
      (click)="openAddEditDialog(false)"
    >
      {{ "ACCOUNTING.ADD_NEW" | translate }}
    </button>

    <div class="datepicker-pay">
      <mat-form-field class="matDatepickerStart" appearance="outline">
        <input
          matInput
          onfocus="this.select()"
          [matDatepicker]="startDatePicker"
          (dateInput)="onDateChange()"
          placeholder="{{ 'ACCOUNTING.START_DATE' | translate }}"
          [(ngModel)]="startDate"
        />
        <mat-label>{{ "ACCOUNTING.START_DATE" | translate }}</mat-label>

        <mat-datepicker-toggle
          matSuffix
          [for]="startDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="matDatepickerEnd" appearance="outline">
        <input
          matInput
          onfocus="this.select()"
          (dateInput)="onDateChange()"
          [matDatepicker]="endDatePicker"
          placeholder="{{ 'ACCOUNTING.END_DATE' | translate }}"
          [min]="startDate"
          [(ngModel)]="endDate"
        /><mat-label>{{ "ACCOUNTING.END_DATE" | translate }}</mat-label>
        <mat-datepicker-toggle
          matSuffix
          [for]="endDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <mat-form-field class="filter-field">
      <input
        matInput
        (blur)="search($event.target.value)"
        (keydown.enter)="search($event.target.value)"
        placeholder="{{ 'ACCOUNTING.SEARCH' | translate }}"
        [value]="searchTerm"
        class="filterInput"
        autocomplete="off"
      />
      <mat-icon matPrefix class="filterIcon">search</mat-icon>
    </mat-form-field>

    <button
      mat-raised-button
      (click)="exportCsv()"
      [disabled]="loading || this.dataSource?.data?.length == 0"
      class="mat-raised-button export-button"
    >
      {{ "ACCOUNTING.EXPORT" | translate }}
    </button>
  </div>
  <table mat-table matSort matSortDisableClear [dataSource]="dataSource">
    <ng-container matColumnDef="type">
      <th mat-header-cell mat-sort-header="type" *matHeaderCellDef>
        {{ "ACCOUNTING.TYPE" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let accounting"
        class="number-truncate"
        (click)="openAddEditDialog(true, accounting.id)"
      >
        <label matTooltip="{{ accounting?.accountingType?.name }}">{{
          accounting?.accountingType?.name
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell mat-sort-header="date" *matHeaderCellDef>
        {{ "ACCOUNTING.DATE" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let accounting"
        class="number-truncate"
        (click)="openAddEditDialog(true, accounting.id)"
      >
        <label
          matTooltip="{{ accounting?.date | tenantDate | date : 'MM/dd/y ' }}"
          >{{ accounting?.date | tenantDate | date : "MM/dd/y " }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="vendor">
      <th mat-header-cell mat-sort-header="vendor" *matHeaderCellDef>
        {{ "ACCOUNTING.VENDOR" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let accounting"
        class="number-truncate"
        (click)="openAddEditDialog(true, accounting.id)"
      >
        <label matTooltip="{{ accounting?.vendor?.name }}">{{
          accounting?.vendor?.name
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell mat-sort-header="description" *matHeaderCellDef>
        {{ "ACCOUNTING.DESCRIPTION" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let accounting"
        class="number-truncate"
        (click)="openAddEditDialog(true, accounting.id)"
      >
        <label matTooltip="{{ accounting?.description }}">{{
          accounting?.description
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th
        mat-header-cell
        mat-sort-header="amount"
        *matHeaderCellDef
        class="number-truncate header-right"
        arrowPosition="before"
      >
        {{ "ACCOUNTING.AMOUNT" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let accounting"
        class="number-truncate rightAlign"
        (click)="openAddEditDialog(true, accounting.id)"
      >
        <label matTooltip="{{ accounting?.amount | currency }}">{{
          accounting?.amount | currency
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="comment">
      <th mat-header-cell mat-sort-header="comment" *matHeaderCellDef>
        {{ "ACCOUNTING.COMMENT" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let accounting"
        class="number-truncate"
        (click)="openAddEditDialog(true, accounting.id)"
      >
        <label matTooltip="{{ accounting?.comment }}">{{
          accounting?.comment
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="truck">
      <th mat-header-cell mat-sort-header="truck" *matHeaderCellDef>
        {{ "ACCOUNTING.TRUCK" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let accounting"
        class="number-truncate"
        (click)="openAddEditDialog(true, accounting.id)"
      >
        <label matTooltip="{{ accounting?.truck?.truckNumber }}">{{
          accounting?.truck?.truckNumber
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="driver">
      <th mat-header-cell mat-sort-header="driver" *matHeaderCellDef>
        {{ "ACCOUNTING.DRIVER" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let accounting"
        class="number-truncate"
        (click)="openAddEditDialog(true, accounting.id)"
      >
        <label
          matTooltip="{{ accounting?.driver?.firstname }} {{
            accounting?.driver?.lastname
          }}"
          >{{ accounting?.driver?.firstname }}
          {{ accounting?.driver?.lastname }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="category">
      <th mat-header-cell mat-sort-header="category" *matHeaderCellDef>
        {{ "ACCOUNTING.CATEGORY" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let accounting"
        class="number-truncate"
        (click)="openAddEditDialog(true, accounting.id)"
      >
        <label matTooltip="{{ accounting?.accountingCategory?.name }}">{{
          accounting?.accountingCategory?.name
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell mat-sort-header="status" *matHeaderCellDef>
        {{ "ACCOUNTING.STATUS" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let accounting"
        class="number-truncate"
        (click)="openAddEditDialog(true, accounting.id)"
      >
        <label matTooltip="{{ accounting?.accountingStatus?.name }}">{{
          accounting?.accountingStatus?.name
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let accounting">
        <button
          mat-flat-button
          class="btnDelete button-accounting"
          color="warn"
          (click)="openComfirmDeleteDialog(accounting.id)"
        >
          <span>{{ "ACCOUNTING.DELETE" | translate }}</span>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let accounting">
        <button
          mat-flat-button
          class="btnDialog button-accounting"
          (click)="openAddEditDialog(false, accounting.id)"
        >
          <span>{{ "ACCOUNTING.EDIT" | translate }}</span>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="rowCursorPointer"
    ></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="displayedColumns"
      class="table-footer"
    ></tr>
  </table>
  <div class="divPaginator">
    <mat-paginator
      (page)="onChangePage($event)"
      class="paging"
      aria-label="Select page"
      [length]="totalSize"
      [showFirstLastButtons]="true"
      [pageIndex]="currentPage"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
  </div>
</div>
