import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PayrollListItemDto } from '../../_models/_payroll/payrollListIitemDto';
import { SortData } from '../../_models/common/sortData';
import { TableType } from '../../_models/enums/tableType';
import { DriverService } from '../../_services';
import { LocalStorageService } from '../../_services/local.storage.service';
import { LocalStorageSortService } from '../../_services/local.storage.sort.service';

@Component({
  selector: 'app-payrolls',
  templateUrl: './payrolls.component.html',
  styleUrls: ['./payrolls.component.scss'],
})
export class PayrollsComponent implements OnInit, AfterViewInit {
  payrollList: Array<PayrollListItemDto>;
  sortData: SortData;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private driverService: DriverService,
    private localStorageService: LocalStorageService,
    private localStorageSortService: LocalStorageSortService
  ) {}

  dataSource = new MatTableDataSource<PayrollListItemDto>();
  ngAfterViewInit() {
    setTimeout(() => {
      this.sort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.sort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.PayrollDrivers,
        this.sort
      );
      switch (property) {
        case 'driverFullName':
          return item.driverFullName;
        case 'truckNumber':
          return item.truckNumber;
        case 'payrollTypeName':
          return item.payrollTypeName;
        default:
          return item[property];
      }
    };
  }
  ngOnInit() {
    this.sortData = this.localStorageSortService.getSortData(
      TableType.PayrollDrivers
    );
    this.localStorageService.setExistingPayrollDataUse(false);
    this.getPayrollList();
  }
  getPayrollList() {
    this.driverService.getPayrollList().subscribe((response) => {
      this.dataSource.data = response.collection;
      this.payrollList = response.collection;
    });
  }
  getCountOfElement() {
    return this.payrollList ? this.payrollList.length : 0;
  }

  displayedColumns: string[] = [
    'driverFullName',
    'truckNumber',
    'payrollTypeName',
  ];
}
