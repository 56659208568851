<div class="wrapperTable" id="wrapperManageTables">
  <div class="addNew">
    <button mat-icon-button class="refresh" (click)="refresh()">
      <mat-icon
        matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
        matTooltipPosition="below"
        >refresh</mat-icon
      >
    </button>
    <button
      *ngIf="userDataService.isAdminDispatcher"
      mat-flat-button
      class="btnDialog"
      (click)="openDialogEditTruck(false)"
    >
      {{ "TRUCKS.ADD_TRUCK_BTN" | translate }}
    </button>
    <mat-form-field class="filterField">
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="{{ 'TRUCKS.FILTER' | translate }}"
        class="filterInput"
        [value]="searchTerm"
        autocomplete="off"
      />
      <mat-icon matPrefix class="filterIcon">search</mat-icon>
    </mat-form-field>
    <mat-slide-toggle
      [(ngModel)]="showInactive"
      (change)="refresh()"
      color="primary"
      class="activeToggle"
      matTooltip="{{ 'TRUCKS.SHOW_INACTIVE' | translate }}"
      matTooltipPosition="after"
    ></mat-slide-toggle>
  </div>

  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    id="mat-table"
  >
    <ng-container matColumnDef="truckNumber">
      <th mat-header-cell mat-sort-header="truckNumber" *matHeaderCellDef>
        {{ "TRUCKS.TRUCK_NO" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let truck"
        (click)="openDialogEditTruck(true, truck.id)"
        class="number-truncate"
      >
        <label matTooltip="{{ truck.truckNumber }}">{{
          truck.truckNumber
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{ "TRUCKS.COUNT" | translate }} {{ getCountOfElement(type) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="owner">
      <th mat-header-cell mat-sort-header="owner" *matHeaderCellDef>
        {{ "TRUCKS.OWNER" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let truck"
        (click)="openDialogEditTruck(true, truck.id)"
        class="text-truncate"
      >
        <label matTooltip="{{ truck.owner }}">
          {{ truck.owner }}
        </label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="driver">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "TRUCKS.DRIVER" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let truck"
        (click)="openDialogEditTruck(true, truck.id)"
        class="text-truncate"
      >
        <label
          *ngIf="truck.driver"
          matTooltip="{{
            truck.driver.firstname + ' ' + truck.driver.lastname
          }}"
          >{{ truck.driver.firstname + " " + truck.driver.lastname }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="licensePlate">
      <th mat-header-cell mat-sort-header="licensePlate" *matHeaderCellDef>
        {{ "TRUCKS.LICENSE_PLATE" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let truck"
        (click)="openDialogEditTruck(true, truck.id)"
        class="number-truncate"
      >
        <label matTooltip="{{ truck.licensePlate }}">{{
          truck.licensePlate
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="vinNumber">
      <th mat-header-cell mat-sort-header="vinNumber" *matHeaderCellDef>
        {{ "TRUCKS.VIN_NUMBER" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let truck"
        (click)="openDialogEditTruck(true, truck.id)"
        class="number-truncate"
      >
        <label matTooltip="{{ truck.vinNumber }}">{{ truck.vinNumber }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="registrationExpirationDate">
      <th
        mat-header-cell
        mat-sort-header="registrationExpirationDate"
        *matHeaderCellDef
      >
        <label
          matTooltip="{{ 'TRUCKS.REGISTRATION_EXPIRATION_DATE' | translate }}"
          >{{ "TRUCKS.REGISTRATION_EXPIRATION_SHORT" | translate }}</label
        >
      </th>
      <td
        mat-cell
        *matCellDef="let truck"
        (click)="openDialogEditTruck(true, truck.id)"
      >
        <label
          matTooltip="{{
            truck.registrationExpirationDate | tenantDate | date : 'MM/dd/y '
          }}"
        >
          {{
            truck.registrationExpirationDate | tenantDate | date : "MM/dd/y "
          }}
        </label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="due_date_registration">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="dueMargin">
        <label matTooltip="{{ 'TRAILERS.DUE_DATE' | translate }}" class="due">{{
          "TRAILERS.DUE_SHORT" | translate
        }}</label>
      </th>
      <td
        mat-cell
        *matCellDef="let truck"
        (click)="openDialogEditTruck(true, truck.id)"
      >
        <button
          mat-mini-fab
          *ngIf="truck.registrationExpirationDate"
          [ngClass]="truck.registrationExpirationDate + '' | colorSpanTodayDays"
        >
          {{ truck.registrationExpirationDate | spanTodayDays }}
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="annualInspectionExpirationDate">
      <th
        mat-header-cell
        mat-sort-header="annualInspectionExpirationDate"
        *matHeaderCellDef
      >
        <label
          matTooltip="{{
            'TRUCKS.ANNUAL_INSPECTION_EXPIRATION_DATE' | translate
          }}"
        >
          {{ "TRUCKS.ANNUAL_INSPECTION_EXPIRATION_SHORT" | translate }}</label
        >
      </th>
      <td
        mat-cell
        *matCellDef="let truck"
        (click)="openDialogEditTruck(true, truck.id)"
      >
        <label
          matTooltip="{{
            truck.annualInspectionExpirationDate
              | tenantDate
              | date : 'MM/dd/y '
          }}"
        >
          {{
            truck.annualInspectionExpirationDate
              | tenantDate
              | date : "MM/dd/y "
          }}
        </label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="due_date_annual">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        matTooltip=""
        class="dueMargin"
      >
        <label matTooltip="{{ 'TRUCKS.DUE_DATE' | translate }}" class="due">
          {{ "TRUCKS.DUE_SHORT" | translate }}</label
        >
      </th>
      <td
        mat-cell
        *matCellDef="let truck"
        (click)="openDialogEditTruck(true, truck.id)"
      >
        <button
          mat-mini-fab
          *ngIf="truck.annualInspectionExpirationDate"
          [ngClass]="
            truck.annualInspectionExpirationDate + '' | colorSpanTodayDays
          "
        >
          {{ truck.annualInspectionExpirationDate | spanTodayDays }}
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="quarterlyInspectionDue">
      <th
        mat-header-cell
        mat-sort-header="quarterlyInspectionDue"
        *matHeaderCellDef
      >
        <label matTooltip="{{ 'TRUCKS.QUARTERLY_INSPECTION' | translate }}">
          {{ "TRUCKS.QUARTERLY_INSPECTION_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let truck"
        (click)="openDialogEditTruck(true, truck.id)"
      >
        <label
          matTooltip="{{
            truck.quarterlyInspectionDue | tenantDate | date : 'MM/dd/y '
          }}"
        >
          {{ truck.quarterlyInspectionDue | tenantDate | date : "MM/dd/y " }}
        </label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="due_date_quarterly">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="dueMargin">
        <label matTooltip="{{ 'TRAILERS.DUE_DATE' | translate }}" class="due">
          {{ "TRAILERS.DUE_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let truck"
        (click)="openDialogEditTruck(true, truck.id)"
      >
        <button
          mat-mini-fab
          *ngIf="truck.quarterlyInspectionDue"
          [ngClass]="truck.quarterlyInspectionDue + '' | colorSpanTodayDays"
        >
          {{ truck.quarterlyInspectionDue | spanTodayDays }}
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef>
        {{ "TRUCKS.ACTIVE" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let truck"
        (click)="openDialogEditTruck(true, truck.id)"
      >
        <mat-icon *ngIf="toBool(truck.isActive)" color="primary"
          >check</mat-icon
        >
        <mat-icon *ngIf="!toBool(truck.isActive)" color="warn">close</mat-icon>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="alignCenter">
        {{ "COMMON.ACTION" | translate }}
      </th>
      <td mat-cell *matCellDef="let truck" class="alignCenter">
        <button
          *ngIf="userDataService.isAdminDispatcher"
          mat-button
          class="edit"
          (click)="openDialogEditTruck(false, truck.id)"
        >
          {{ "COMMON.EDIT" | translate }}
        </button>
        <button [matMenuTriggerFor]="menu" mat-icon-button class="icon-color">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openDialogEditTruck(true, truck.id)">
            {{ "COMMON.VIEW" | translate }}
          </button>
          <button
            *ngIf="userDataService.isAdminDispatcher"
            mat-menu-item
            (click)="openDialogEditTruck(false, truck.id)"
          >
            {{ "COMMON.EDIT" | translate }}
          </button>
          <button
            *ngIf="userDataService.isAdminDispatcher"
            mat-menu-item
            class="delete-button"
            (click)="openDialogComfirmDeleteTruck(truck.id)"
          >
            {{ "COMMON.DELETE" | translate }}
          </button>
        </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="rowCursorPointer"
    ></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="displayedColumns"
      class="table-footer"
    ></tr>
  </table>
</div>
