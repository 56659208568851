import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import Common from 'src/app/_helpers/common';
import { SpanTodayPipe } from 'src/app/_helpers/pipes/span.today.date.pipe';
import { Paginator } from 'src/app/_models/common/paginator';
import { SortData } from 'src/app/_models/common/sortData';
import { DriverModel } from 'src/app/_models/driver/driver';
import { TableType } from 'src/app/_models/enums/tableType';
import { DriverService, TenantService } from 'src/app/_services';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';
import { UserDataService } from 'src/app/_services/user.data.service';
import { DriverApplicationComponent } from 'src/app/driver-application/driver-application.component';
import { ComfirmDialogDriverComponent } from './edit-delete-driver/comfirm-dialog-driver/comfirm-dialog-driver.component';
import { EditDriverDialogComponent } from './edit-delete-driver/edit-driver-dialog/edit-driver-dialog.component';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss'],
})
export class DriversComponent {
  driversData: Array<DriverModel>;
  showInactive = false;
  searchTerm = '';
  sortData: SortData;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private driverService: DriverService,
    private tenantService: TenantService,
    public userDataService: UserDataService,
    private localStorageSortService: LocalStorageSortService,
    public dialog: MatDialog
  ) {}

  spanTodayDaysPipe = new SpanTodayPipe(this.tenantService);
  dataSource = new MatTableDataSource<DriverModel>();

  ngOnInit() {
    this.sortData = this.localStorageSortService.getSortData(
      TableType.ManageDrivers
    );
    this.getDrivers();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.sort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.ManageDrivers,
        this.sort
      );
      switch (property) {
        case 'driverName':
          return item.firstname + item.lastname;
        case 'dispatcherFullName':
          if (item.dedicatedDispatcher) {
            return (
              item.dedicatedDispatcher.firstname +
              item.dedicatedDispatcher.lastname
            );
          }
          return '';
        case 'address':
          if (item.location) {
            return (
              Common.getSafeString(item.location?.city) +
              Common.getSafeString(item.location?.poBox) +
              Common.getSafeString(item.location?.state)
            );
          }
          return '';
        case 'cdlExpDueNumber':
          return this.spanTodayDaysPipe.transform(item.cdlExpirationDate);
        case 'medicalExpDueNumber':
          return this.spanTodayDaysPipe.transform(
            item.medicalCardExpirationDate
          );
        case 'annualDrivingCheckNumber':
          return this.spanTodayDaysPipe.transform(
            item.annualDrivingRecordCheckDueDate
          );
        case 'randomDrugTestDueNumber':
          return this.spanTodayDaysPipe.transform(item.randomDrugTestDueDate);
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.searchTerm = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  toBool(boolVariable: any) {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  openDialogComfirmDeleteDriver(id: number) {
    const dialogRef = this.dialog.open(ComfirmDialogDriverComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: id,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getDrivers();
    });
  }

  openDialogEditDriver(readOnly: boolean, id?: number) {
    const dialogRef = this.dialog.open(EditDriverDialogComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: {
        id,
        readOnly,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getDrivers();
    });
  }

  getDrivers() {
    this.driverService
      .getAllDrivers(this.getPaginator(), !this.showInactive)
      .pipe(first())
      .subscribe((response) => {
        this.dataSource.data = response.collection;
        this.driversData = response.collection;
      });
  }

  openDriverApp(readOnly: boolean, id?: number) {
    const dialogRef = this.dialog.open(DriverApplicationComponent, {
      disableClose: true,
      width: '1480px',
      position: { top: '20px' },
      data: {
        id,
        readOnly,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  private getPaginator(): Paginator {
    const dropDownPaginator = new Paginator();
    dropDownPaginator.pageSize = 1000;
    dropDownPaginator.sortBy = this.sortData.activeColumn;
    dropDownPaginator.sortOrder = this.sortData.order;
    return dropDownPaginator;
  }

  getCountOfElement() {
    return this.driversData ? this.driversData.length : 0;
  }

  refresh() {
    this.applyFilter('');
    this.getDrivers();
  }
  displayedColumns: string[] = [
    'driverName',
    'address',
    'phoneNumber',
    'dispatcherFullName',
    'cdlNumber',
    'cdlExpirationDate',
    'cdlExpDueNumber',
    'medicalCardExpirationDate',
    'medicalExpDueNumber',
    'annualDrivingRecordCheckDueDate',
    'annualDrivingCheckNumber',
    'randomDrugTestDueDate',
    'randomDrugTestDueNumber',
    'employmentStart',
    'employmentEnd',
    'driver_app',
    'isActive',
    'safetyInfo',
    'action',
  ];
}
