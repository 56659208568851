import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { SpanTodayPipe } from 'src/app/_helpers/pipes/span.today.date.pipe';
import { SortData } from 'src/app/_models/common/sortData';
import { TableType } from 'src/app/_models/enums/tableType';
import { TruckModel } from 'src/app/_models/truck';
import { TenantService, TruckService } from 'src/app/_services';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';
import { UserDataService } from 'src/app/_services/user.data.service';
import { ComfirmDialogComponent } from './edit-delete-truck/comfirm-dialog/comfirm-dialog.component';
import { EditTruckDialogComponent } from './edit-delete-truck/edit-truck-dialog/edit-truck-dialog.component';

@Component({
  selector: 'app-trucks',
  templateUrl: './trucks.component.html',
  styleUrls: ['./trucks.component.scss'],
})
export class TrucksComponent {
  trucksData: Array<TruckModel>;
  searchTerm = '';
  sortData: SortData;
  showInactive = false;
  constructor(
    private trucksService: TruckService,
    private tenantService: TenantService,
    private localStorageSortService: LocalStorageSortService,
    public userDataService: UserDataService,
    public dialog: MatDialog
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  spanTodayDaysPipe = new SpanTodayPipe(this.tenantService);
  dataSource = new MatTableDataSource<TruckModel>();
  totalSize: number;
  ngOnInit() {
    this.getTrucks();
    this.dataSource.filterPredicate = (
      data: TruckModel,
      filtersJson: string
    ) => {
      const matchFilter = [];
      const filters = JSON.parse(filtersJson);

      filters.forEach((filter) => {
        const val = data[filter.id] === null ? '' : data[filter.id];
        matchFilter.push(
          val.toLowerCase().includes(filter.value.toLowerCase())
        );
      });
      return matchFilter.every(Boolean);
    };
    this.sortData = this.localStorageSortService.getSortData(
      TableType.ManageTrucks
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.sort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.ManageTrucks,
        this.sort
      );
      switch (property) {
        case 'driver':
          return item.driver
            ? item.driver.firstname + item.driver.lastname
            : '';
        case 'due_date_registration':
          return this.spanTodayDaysPipe.transform(
            item.registrationExpirationDate
          );
        case 'due_date_annual':
          return this.spanTodayDaysPipe.transform(
            item.annualInspectionExpirationDate
          );
        case 'due_date_quarterly':
          return this.spanTodayDaysPipe.transform(item.quarterlyInspectionDue);
        default:
          return item[property];
      }
    };
  }

  applyFilter(filterValue: string) {
    this.searchTerm = filterValue;
    const tableFilters = [];
    tableFilters.push({
      id: 'truckNumber',
      value: filterValue,
    });

    this.dataSource.filter = JSON.stringify(tableFilters);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  toBool(boolVariable: any) {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  openDialogComfirmDeleteTruck(id: number) {
    const dialogRef = this.dialog.open(ComfirmDialogComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: id,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getTrucks();
    });
  }

  openDialogEditTruck(readOnly: boolean, id?: number) {
    const dialogRef = this.dialog.open(EditTruckDialogComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: {
        id,
        readOnly,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getTrucks();
    });
  }

  getTrucks() {
    this.trucksService
      .getTrucks(!this.showInactive)
      .pipe(first())
      .subscribe((response) => {
        this.dataSource.data = response;
        this.trucksData = response;
      });
  }

  getCountOfElement(value) {
    return this.trucksData ? this.trucksData.length : 0;
  }
  refresh() {
    this.applyFilter('');
    this.getTrucks();
  }

  displayedColumns: string[] = [
    'truckNumber',
    'owner',
    'driver',
    'licensePlate',
    'vinNumber',
    'registrationExpirationDate',
    'due_date_registration',
    'annualInspectionExpirationDate',
    'due_date_annual',
    'quarterlyInspectionDue',
    'due_date_quarterly',
    'isActive',
    'action',
  ];
}
