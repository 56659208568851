import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import Common from '../_helpers/common';
import { CodeModel } from '../_models/code';
import { CommentModel } from '../_models/comments';
import { CustomColumn } from '../_models/common/customColumn';
import { Paginator } from '../_models/common/paginator';
import { SortData } from '../_models/common/sortData';
import { CustomerModel } from '../_models/customer';
import { DriverModel } from '../_models/driver/driver';
import { TableType } from '../_models/enums/tableType';
import { LoadModel } from '../_models/load/load';
import { LoadDocument } from '../_models/load/loadDocument';
import { LoadFilter } from '../_models/load/loadFilter';
import { LoadPage } from '../_models/load/loadPage';
import { LoadsTotals } from '../_models/load/loadsTotals';
import { LoadService, ToastrTranslateService } from '../_services';
import { CodeFactory } from '../_services/factory/code.factory.service';
import { LocalStorageService } from '../_services/local.storage.service';
import { LocalStorageSortService } from '../_services/local.storage.sort.service';
import { ConfirmDialogChangeStatusComponent } from '../loads/confirm-dialog-change-status/confirm-dialog-change-status.component';
import { DownloadRcComponent } from '../loads/download-rc/download-rc.component';
import { EmailDocumentsComponent } from '../loads/email-documents/email-documents.component';
import { LoadsCommentComponent } from '../loads/loads-comment-dialog/loads-comment.component';
import { LoadCommentDialogComponent } from '../loads/new-load/load-comment-dialog.component';
import { PrintInvoiceDialogComponent } from '../loads/new-load/print-invoice-dialog/print-invoice-dialog.component';
import LoadsHelper from '../loads/utils/loads-helper';
import { PayrollPdfPreviewPopupComponent } from '../payroll/payroll-pdf-preview-popup/payroll-pdf-preview-popup.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  public displayedColumns: string[] = [
    'loadNumber',
    'status',
    'driver',
    'dispatcher',
    'truck',
    'trailer',
    'eq_type',
    'customer',
    'origin',
    'destination',
    'pickup_date',
    'delivery_date',
    'milage',
    'dead_head',
    'rate',
    'rpm',
    'tonu',
    'haul_type',
    'order',
    'note',
    'comment',
    'rc',
    'icon_ivoice',
    'BOL',
    'pod_scans',
    'sendMail',
    'print_ivoice',
    'lumper_fee',
    'detention_fee',
    'other',
    'advance',
    'total_invoice',
    'ageing',
    'columns',
  ];
  private _routerSub = Subscription.EMPTY;
  public allColumnList: CustomColumn[] = [];
  public selectedColumnList: CustomColumn[] = [];
  public pendingCount = 0;
  loadsData: LoadModel[] = [];
  loadPage: LoadPage;
  dataSource = new MatTableDataSource<LoadModel>();
  loadsTotals = new LoadsTotals();

  loads_coment: CommentModel[] = [];

  PAGE_ALL_STATUS_LIST = [
    'OPEN',
    'RESERVED',
    'PENDING',
    'COVERED',
    'DISPATCHED',
    'LOADING',
    'ON_ROUTE',
    'UNLOADING',
    'IN_YARD',
    'DELIVERED',
    'COMPLETED',
    'INVOICED',
    'PAID',
  ];
  pageAllStatusIdList = new Array<number>();
  totalSize = 0;
  pageSizeOptions: number[];
  pageSize;
  currentPage;
  searchTerm = '';
  sortData: SortData;

  pageEvent: PageEvent;
  LoadModel;
  array: any;
  asc?: boolean;
  driver: DriverModel[];
  selection = new SelectionModel<LoadModel>(true, []);
  activeButton = '';
  hasHoldFilter = false;
  fileNameDialogRef: MatDialogRef<LoadCommentDialogComponent>;
  loadId: number;

  datasControl = new UntypedFormControl();
  columnsControl = new UntypedFormControl();

  selectedColumnStorageKey = 'selectedColumnsHome';

  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;
  statusTypeList: CodeModel[] = [];

  constructor(
    private loadService: LoadService,
    private localStorageService: LocalStorageService,
    private codeFactory: CodeFactory,
    private route: ActivatedRoute,
    private router: Router,
    private localStorageSortService: LocalStorageSortService,
    private translateService: TranslateService,
    private toastrTranslateService: ToastrTranslateService,
    private loadsHelper: LoadsHelper,
    public dialog: MatDialog
  ) {
    this.loadPage = new LoadPage();
    this.codeFactory.getStatusList().then((data) => {
      this.statusTypeList = data;
      this.getLoadData();
    });
    this.route.params.subscribe((params) => {
      if (!params['activeButton'] || params['activeButton'] == '') {
        this.activeButton = 'ACTIVE';
      } else if (params['activeButton'] == 'ALL') {
        this.activeButton = 'ALL';
      } else {
        this.activeButton = params['activeButton'];
      }

      this.hasHoldFilter = this.toBool(params['hasHold']);
      if (params['searchTerm']) {
        this.searchTerm = params['searchTerm'];
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.matSort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.matSort;
    });

    this.matSort.sortChange.subscribe(() => {
      this.refresh();
      this.currentPage = 0;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'driver':
          return item?.driver?.firstname + item?.driver?.lastname;
        case 'dispatcher':
          return item?.dispatcher?.firstname + item?.dispatcher?.lastname;
        case 'truck':
          return item?.truck?.truckNumber;
        case 'trailer':
          return item?.trailer?.trailerNumber;
        case 'eq_type':
          return item?.equipmentType?.name
            ? this.translateService.instant(
                'EQ_TYPE_SHORT.' + item?.equipmentType?.name
              )
            : '';
        case 'customer':
          return item?.customer?.name;
        case 'origin':
          return item?.originLocation?.city
            ? item?.originLocation?.city + item?.originLocation?.state
            : item?.originLocation?.state;
        case 'destination':
          return item?.destinationLocation?.city
            ? item?.destinationLocation?.city + item?.destinationLocation?.state
            : item?.destinationLocation?.state;
        case 'haul_type':
          return item?.haulType?.name
            ? this.translateService.instant(
                'HAUL_TYPE_SHORT.' + item?.haulType?.name
              )
            : '';
        case 'status':
          return this.translateService.instant(
            'LOAD_STATUS.' + item?.status?.id
          );
        case 'comment':
          return item?.comments?.length;
        default:
          return item[property];
      }
    };
  }

  ngOnInit() {
    this._routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.globalSearch(this.searchTerm);
      }
    });
    this.loadService.loadAdded.subscribe((_) => this.refresh());
    this.dataSource.paginator = this.matPaginator;
    this.initPaginatorOptions();
    this.initializeColumnProperties(this.activeButton);
    this.sortData = this.localStorageSortService.getSortData(
      TableType.HomeLoads
    );
  }

  ngOnDestroy() {
    this._routerSub.unsubscribe();
  }

  initPaginatorOptions() {
    this.pageSize = this.localStorageService.getPagginatorPageSize();
    this.pageSizeOptions = [10, 25, 50, 100];
    this.currentPage = 0;
  }

  private getPaginator(): Paginator {
    const paginator = new Paginator();
    paginator.pageSize = this.pageSize;
    paginator.pageNumber = this.currentPage + 1;
    paginator.searchTerm = this.searchTerm;
    paginator.sortBy = this.matSort?.active;
    paginator.sortOrder = this.matSort?.direction;
    this.localStorageSortService.setSortData(TableType.HomeLoads, this.matSort);
    return paginator;
  }

  openCommentsDialog(commentList: CommentModel[], loadNumber: string) {
    const dialogRef = this.dialog.open(LoadsCommentComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: { commentList, loadNumber },
    });
  }

  openDownloadRC(
    loadId: number,
    loadNumber: string,
    documentList: Array<LoadDocument>,
    statusName: string
  ) {
    const documentsDownloadDialogRef = this.dialog.open(DownloadRcComponent, {
      disableClose: false,
      position: { top: '130px' },
      data: { loadId, loadNumber, documentList, statusName },
    });
    documentsDownloadDialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }

  sendMailToCustomer(
    loadId: number,
    loadNumber: string,
    customer: CustomerModel,
    documentList: Array<LoadDocument>
  ) {
    const documentsDialogRef = this.dialog.open(EmailDocumentsComponent, {
      disableClose: true,
      position: { top: '130px' },
      data: { loadId, loadNumber, customer, documentList },
    });
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o1 && o2) {
      return o1.id === o2.id;
    }
    return false;
  }

  getTotals(loads: LoadModel[]) {
    this.loadsTotals = this.loadsHelper.getTotals(loads);
  }

  async getPdfInvoice(loadId: number, includeNotes: boolean) {
    try {
      let data = await this.loadService.getPdfInvoice(loadId, includeNotes);
      let file = new Blob([data], { type: 'application/pdf' });
      this.dialog.open(PayrollPdfPreviewPopupComponent, {
        width: '1024px',
        data: file,
      });
    } catch (error) {
      this.toastrTranslateService.error(error, 'COMMON.ERROR_DATA');
    }
  }

  openPrintInoviceDialog(loadId: number, statusName: string) {
    const printInvoiceDialogRef = this.dialog.open(
      PrintInvoiceDialogComponent,
      {
        disableClose: true,
        //position: { top: '20px' },
        data: { loadId, statusName },
      }
    );
    printInvoiceDialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }
  toggleColumn() {
    this.displayedColumns = [];
    this.selectedColumnList.forEach((element) => {
      this.displayedColumns.push(element.name);
    });
    this.localStorageService.addSelectedColumns(
      this.selectedColumnList,
      this.selectedColumnStorageKey + '_' + this.activeButton
    );
  }

  initializeColumnProperties(status?: string) {
    let storageStatusString = '';
    if (status) storageStatusString = status;
    this.selectedColumnList = this.localStorageService.getSelectedColumns(
      this.selectedColumnStorageKey + '_' + storageStatusString
    );
    if (this.selectedColumnList?.length > 0) {
      this.displayedColumns = [];
      this.selectedColumnList.forEach((element) => {
        this.displayedColumns.push(element.name);
      });
      this.activeButton = storageStatusString;
      this.pushToAllColumns(this.getDisplayedColumns(status));
    } else {
      this.displayedColumns = this.getDisplayedColumns(status);
      this.pushToAllColumns(this.displayedColumns);
      this.selectedColumnList = this.allColumnList;
    }
  }

  private pushToAllColumns(displayedColumns: string[]) {
    this.allColumnList = [];
    displayedColumns.forEach((element, index) => {
      let id = '';
      let elementClean = element.toLowerCase().replace('_', '');
      for (let i = 0; i < elementClean.length; i++) {
        id = id + (elementClean.charCodeAt(i) - 97 + 1).toString();
      }
      this.allColumnList.push({
        id: +id,
        possition: index,
        name: element,
        isActive: true,
      });
    });
  }

  clearSelectedColumns() {
    this.localStorageService.clearSelectedColumns(
      this.selectedColumnStorageKey + '_' + this.activeButton
    );
    this.initializeColumnProperties(this.activeButton);
  }

  public onChangePage(event?: PageEvent): PageEvent {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.localStorageService.setPagginatorPageSize(event.pageSize);
    this.getLoadData();
    return event;
  }
  onChange() {
    this.selection.clear();
  }

  openDialogConfirmStatus(
    loadId: number,
    statusId: number,
    loadNumber: string
  ) {
    const dialogRef = this.dialog.open(ConfirmDialogChangeStatusComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: { loadId, statusId, loadNumber },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getLoadData();
    });
  }

  getLoadFromButtonFilter(resetCurrentPage?: boolean, status?: string) {
    this.searchTerm = '';
    this.getLoadData(resetCurrentPage, status);
  }

  getLoadFromCheckedFilter(event, resetCurrentPage?: boolean, status?: string) {
    event.checked ? (this.hasHoldFilter = true) : (this.hasHoldFilter = false);
    this.getLoadData(resetCurrentPage, status);
  }

  getLoadData(resetCurrentPage?: boolean, status?: string) {
    let unMappedStatus = '';
    this.columnsControl.valueChanges.subscribe((columns) => {
      this.displayedColumns = columns;
    });
    let statusId;

    if (!status) {
      status = this.activeButton;
    }

    if (status == 'ALL' || '') {
      unMappedStatus = 'ALL';
      status = null;
    }

    if (status == 'ACTIVE') {
      unMappedStatus = 'ACTIVE';
      this.pageAllStatusIdList = new Array<number>();
      status = null;
    } else if (status) {
      statusId = this.getStatusIdByName(status);
    } else if (this.pageAllStatusIdList.length == 0) {
      this.PAGE_ALL_STATUS_LIST.forEach((status) => {
        this.pageAllStatusIdList.push(this.getStatusIdByName(status));
      });
    }

    if (resetCurrentPage) {
      this.currentPage = 0;
    }

    let filter = new LoadFilter();
    filter.hasHold = this.hasHoldFilter;

    this.loadService
      .getLoadsByPage(
        this.getPaginator(),
        statusId,
        this.pageAllStatusIdList,
        filter
      )
      .pipe(first())
      .subscribe((response) => {
        response = Common.fixMixCityLocation(response);
        this.dataSource = new MatTableDataSource<LoadModel>(
          response.loads.collection
        );
        this.loadsData = response.loads.collection;
        this.pendingCount = response.pendingLoadsCount;
        if (status == 'ALL' || '') {
          unMappedStatus = 'ALL';
          status = null;
        }
        this.initializeColumnProperties(
          unMappedStatus ? unMappedStatus : status
        );
        this.getTotals(this.loadsData);
        setTimeout(() => {
          this.matPaginator.length = response.loads.count;
          this.matPaginator.pageIndex = this.currentPage;
        });
      });
  }

  refresh() {
    this.getLoadData();
  }

  getStatusIdByName(status: string): number {
    return status && status != 'undefined'
      ? this.statusTypeList.find((x) => x.name == status).id
      : null;
  }

  getCountOfPending(name: 'PENDING') {
    return this.loadsData.filter.name ? this.loadsData.length : 0;
  }

  globalSearch(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.getLoadData(true);
  }

  toBool(boolVariable: any) {
    if (boolVariable && boolVariable != 'undefined') {
      return Boolean(JSON.parse(boolVariable));
    }
    return false;
  }

  getDisplayedColumns(status?: string): string[] {
    switch (status) {
      case 'PENDING': {
        this.activeButton = 'PENDING';
        return [
          'loadNumber',
          'status',
          'driver',
          'dispatcher',
          'truck',
          'origin',
          'destination',
          'pickup_date',
          'delivery_date',
          'milage',
          'dead_head',
          'rate',
          'haul_type',
          'comment',
          'columns',
        ];
      }
      case 'DELIVERED': {
        this.activeButton = 'DELIVERED';
        return [
          'loadNumber',
          'status',
          'driver',
          'dispatcher',
          'truck',
          'origin',
          'destination',
          'pickup_date',
          'delivery_date',
          'rate',
          'haul_type',
          'note',
          'rc',
          'sendMail',
          'print_ivoice',
          'columns',
        ];
      }
      case 'COMPLETED': {
        this.activeButton = 'COMPLETED';
        return [
          'loadNumber',
          'status',
          'driver',
          'dispatcher',
          'truck',
          'origin',
          'destination',
          'pickup_date',
          'delivery_date',
          'total_invoice',
          'haul_type',
          'note',
          'rc',
          'sendMail',
          'print_ivoice',
          'columns',
        ];
      }
      case 'INVOICED': {
        this.activeButton = 'INVOICED';
        return [
          'loadNumber',
          'status',
          'driver',
          'dispatcher',
          'truck',
          'customer',
          'origin',
          'destination',
          'pickup_date',
          'delivery_date',
          'ageing',
          'total_invoice',
          'haul_type',
          'note',
          'rc',
          'sendMail',
          'print_ivoice',
          'columns',
        ];
      }
      case 'PAID': {
        this.activeButton = 'PAID';
        return [
          'loadNumber',
          'status',
          'driver',
          'dispatcher',
          'truck',
          'customer',
          'origin',
          'destination',
          'pickup_date',
          'delivery_date',
          'total_invoice',
          'haul_type',
          'note',
          'rc',
          'sendMail',
          'print_ivoice',
          'columns',
        ];
      }
      case 'DELETED': {
        this.activeButton = 'DELETED';
        return [
          'loadNumber',
          'status',
          'driver',
          'dispatcher',
          'truck',
          'origin',
          'destination',
          'pickup_date',
          'delivery_date',
          'rate',
          'haul_type',
          'rc',
          'sendMail',
          'columns',
        ];
      }
      case 'ALL': {
        this.activeButton = 'ALL';
        return [
          'loadNumber',
          'status',
          'driver',
          'dispatcher',
          'truck',
          'trailer',
          'eq_type',
          'customer',
          'origin',
          'destination',
          'pickup_date',
          'delivery_date',
          'milage',
          'dead_head',
          'rate',
          'rpm',
          'tonu',
          'haul_type',
          'order',
          'note',
          'rc',
          'sendMail',
          'print_ivoice',
          'columns',
        ];
      }
      default:
        this.activeButton = 'ACTIVE';
        return [
          'loadNumber',
          'status',
          'driver',
          'dispatcher',
          'truck',
          'trailer',
          'eq_type',
          'customer',
          'origin',
          'destination',
          'pickup_date',
          'delivery_date',
          'milage',
          'dead_head',
          'rate',
          'rpm',
          'tonu',
          'haul_type',
          'order',
          'comment',
          'columns',
        ];
    }
  }
}
