import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import Common from 'src/app/_helpers/common';
import { SortData } from 'src/app/_models/common/sortData';
import { TableType } from 'src/app/_models/enums/tableType';
import { LocalStorageSortService } from 'src/app/_services/local.storage.sort.service';
import { AnalyticsTruck } from '../../_models/analytics/analytics.truck';
import { AnalyticsService } from '../../_services/analytics.service';

@Component({
  selector: 'app-analytics-truck',
  templateUrl: './analytics-truck.component.html',
  styleUrls: ['./analytics-truck.component.scss'],
})
export class AnalyticsTruckComponent implements OnInit {
  public sumRevenue = 0;
  public sumPayroll = 0;
  public sumMiles = 0;
  startDate = new Date(new Date().getFullYear(), 0, 1);
  endDate = Common.getDateEndOfDay(new Date());
  sortData: SortData;

  displayedColumns: string[] = [
    'truckNumber',
    'totalMiles',
    'totalPayroll',
    'totalRevenue',
  ];

  constructor(
    private analyticsService: AnalyticsService,
    private localStorageSortService: LocalStorageSortService,
    private route: ActivatedRoute
  ) {}
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<AnalyticsTruck>();

  ngOnInit() {
    this.sortData = this.localStorageSortService.getSortData(
      TableType.AnalyticsTruck
    );
    this.getAnalyticsTruck();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.sort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.AnalyticsTruck,
        this.sort
      );
      return item[property];
    };
  }

  refresh() {
    this.getAnalyticsTruck();
  }

  getAnalyticsTruck() {
    this.analyticsService
      .getTruckData(this.startDate, this.endDate)
      .pipe(first())
      .subscribe((response) => {
        this.dataSource.data = response.collection;
        this.sumPayroll = response.sumPayroll;
        this.sumMiles = response.sumMiles;
        this.sumRevenue = response.sumRevenue;
      });
  }
}
