import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SortData } from '../../_models/common/sortData';
import { TableType } from '../../_models/enums/tableType';
import { TransactionModel } from '../../_models/transations';
import { IntegrationService } from '../../_services/integration.service';
import { LocalStorageSortService } from '../../_services/local.storage.sort.service';
import { ToastrTranslateService } from '../../_services/toastr.translate.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../../common/info-dialog/info-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transactions-biling',
  templateUrl: './transactions-biling.component.html',
  styleUrls: ['./transactions-biling.component.scss'],
})
export class TransactionsBilingComponent implements OnInit, AfterViewInit {
  sortData: SortData;
  displayedColumns: string[] = ['created', 'description', 'amount', 'status'];
  public count;
  public infoText: any;
  srcUrl: string =
    'https://www.urbanpro.com/kolkata/self-and-beyond-golf-green/4422880';

  constructor(
    private integrationService: IntegrationService,
    private toastrTranslateService: ToastrTranslateService,
    private localStorageSortService: LocalStorageSortService,
    public dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  @ViewChild(MatSort) matSort: MatSort;

  dataSource = new MatTableDataSource<TransactionModel>();

  ngOnInit(): void {
    this.sortData = this.localStorageSortService.getSortData(
      TableType.SettingsTransactions
    );
    this.getTransactionList();
    this.infoText = this.translateService.instant(
      'TRANSACTIONS.NO_TRANSACTIONS'
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.matSort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.matSort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.SettingsTransactions,
        this.matSort
      );
      return item[property];
    };
  }

  getTransactionList() {
    this.integrationService.getStripeTransations().subscribe({
      next: (response) => {
        this.dataSource.data = response.collection;
        this.count = response.count;
      },
      error: (response) => {
        this.count = 0;
      },
    });
  }

  managePayment(isPaymentEmpty: boolean) {
    const win = window;
    const h = win.top.outerHeight * 0.8;
    const w = win.top.outerWidth * 0.8;
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    if (isPaymentEmpty) {
      const dialogRef = this.dialog.open(InfoDialogComponent, {
        disableClose: true,
        position: { top: '20px' },
        data: {
          key1: 'CONFIRM_DIALOG.TRANSACTIONS_PAYMENT',
        },
      });
      dialogRef.afterClosed().subscribe(() => {
        return win.open(
          environment.stripeAddNewUrl,
          'Stripe Payments',
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
        );
      });
    } else {
      this.integrationService.getStripePortalLink().subscribe({
        next: (response) => {
          return win.open(
            response,
            'Stripe Payments',
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
          );
        },
        error: (response) => {
          this.toastrTranslateService.error(response, 'TRANSACTIONS.FAILED');
        },
      });
    }
  }

  refresh() {
    this.getTransactionList();
  }
}
