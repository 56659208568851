<div class="wrapperTable" id="wrapperManageTables">
  <div class="datePickersDiv">
    <div class="refreshDiv">
      <button mat-icon-button class="refresh" (click)="refresh()">
        <mat-icon
          matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
          matTooltipPosition="below"
          >refresh</mat-icon
        >
      </button>
    </div>

    <mat-form-field class="matDatepickerStart" appearance="outline">
      <input
        matInput
        onfocus="this.select()"
        [matDatepicker]="startDatePicker"
        (dateInput)="refresh()"
        placeholder="Start Date"
        [(ngModel)]="startDate"
      />
      <mat-label>{{ "ANALYTICS.START_DATE" | translate }}</mat-label>

      <mat-datepicker-toggle
        matSuffix
        [for]="startDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="matDatepickerEnd" appearance="outline">
      <input
        matInput
        onfocus="this.select()"
        (dateInput)="refresh()"
        [matDatepicker]="endDatePicker"
        placeholder="End Date"
        [min]="startDate"
        [(ngModel)]="endDate"
      /><mat-label>{{ "ANALYTICS.END_DATE" | translate }}</mat-label>
      <mat-datepicker-toggle
        matSuffix
        [for]="endDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    class="mat-elevation-z8 table"
  >
    <ng-container matColumnDef="truckNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "ANALYTICS.UNIT_NUMBER" | translate }}
      </th>
      <td mat-cell *matCellDef="let truckData">
        {{ truckData.truckNumber }}
      </td>
      <td mat-footer-cell *matFooterCellDef>&#x3A3;</td>
    </ng-container>

    <ng-container matColumnDef="totalRevenue">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        class="header-right"
        arrowPosition="before"
      >
        {{ "ANALYTICS.TOTAL_REVENUE" | translate }}
      </th>
      <td mat-cell *matCellDef="let truckData" class="rightAlign">
        {{ truckData.totalRevenue | currency }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="rightAlign">
        {{ sumRevenue | currency }}
      </td>
    </ng-container>

    <ng-container matColumnDef="totalPayroll">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        class="header-right"
        arrowPosition="before"
      >
        {{ "ANALYTICS.TOTAL_PAYROLL" | translate }}
      </th>

      <td mat-cell *matCellDef="let truckData" class="rightAlign">
        {{ truckData.totalPayroll | currency }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="rightAlign">
        {{ sumPayroll | currency }}
      </td>
    </ng-container>

    <ng-container matColumnDef="totalMiles">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        class="header-right"
        arrowPosition="before"
      >
        {{ "ANALYTICS.TOTAL_MILES" | translate }}
      </th>
      <td mat-cell *matCellDef="let truckData" class="rightAlign">
        {{ truckData.totalMiles | number : "1.2-2" }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="rightAlign">
        {{ sumMiles | number : "1.2-2" }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="displayedColumns"
      class="table-footer"
    ></tr>
  </table>
</div>
