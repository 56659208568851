<div class="wrapperTable" id="wrapperDriverTables">
  <div class="addNew">
  </div>
  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    class="mat-elevation-z8 table-payroll"
    id="mat-table"
  >
    <ng-container matColumnDef="driverFullName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "PAYROLL.FULL_NAME" | translate }}
      </th>
      <td mat-cell *matCellDef="let listItem" class="widthCell">
        {{ listItem.driverFullName }}
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{ "PAYROLL.COUNT" | translate }} {{ getCountOfElement() }}
      </td>
    </ng-container>
    <ng-container matColumnDef="truckNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "PAYROLL.TRUCK" | translate }}
      </th>
      <td mat-cell *matCellDef="let listItem" class="widthCell">
        {{ listItem.truckNumber }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="payrollTypeName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "PAYROLL.PAYROLL_TYPE" | translate }}
      </th>
      <td mat-cell *matCellDef="let listItem" class="widthCell">
        {{
          "PAYROLL.PAYROLL_LIST_TYPE_NAME." + listItem.payrollTypeName
            | translate
        }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let listItem; columns: displayedColumns"
      routerLink="{{
        '/payrolls/truck/' + listItem.truckId + '/driver/' + listItem.driverId
      }}"
    ></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="displayedColumns"
      class="table-footer"
    ></tr>
  </table>
</div>
