<div class="wrapperTable" id="wrapperManageTables">
  <div class="addNew">
    <button mat-icon-button class="refresh" (click)="refresh()">
      <mat-icon
        matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
        matTooltipPosition="below"
        >refresh</mat-icon
      >
    </button>
    <button
      *ngIf="userDataService.isAdminDispatcher"
      mat-flat-button
      class="btnDialog"
      (click)="openDialogEditDispatcher(false)"
    >
      {{ "DISPATCHERS.ADD_DISPATCHER_BTN" | translate }}
    </button>
    <mat-form-field class="filterField">
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="{{ 'DISPATCHERS.FILTER' | translate }}"
        class="filterInput"
        [value]="searchTerm"
        autocomplete="off"
      />
      <mat-icon matPrefix class="filterIcon">search</mat-icon>
    </mat-form-field>
    <mat-slide-toggle
      [(ngModel)]="showInactive"
      (change)="refresh()"
      color="primary"
      class="active"
      matTooltip="{{ 'DISPATCHERS.SHOW_INACTIVE' | translate }}"
      matTooltipPosition="after"
    ></mat-slide-toggle>
  </div>

  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    id="mat-table"
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>
        {{ "DISPATCHERS.POSITION" | translate }}
      </th>
      <td mat-cell *matCellDef="let dispatcher" class="displayNone">
        {{ dispatcher.id }}
      </td>
      <td mat-footer-cell *matFooterCellDef class="displayNone"></td>
    </ng-container>

    <ng-container matColumnDef="dispatcherName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "DISPATCHERS.DISPATCHER_NAME" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let dispatcher"
        (click)="openDialogEditDispatcher(true, dispatcher.id)"
        class="text-truncate"
      >
        <label
          *ngIf="dispatcher.firstname + dispatcher.lastname"
          matTooltip="{{ dispatcher.firstname + ' ' + dispatcher.lastname }}"
          >{{ dispatcher.firstname + " " + dispatcher.lastname }}</label
        >
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{ "DISPATCHERS.COUNT" | translate }} {{ getCountOfElement(type) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "DISPATCHERS.ADDRESS" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let dispatcher"
        (click)="openDialogEditDispatcher(true, dispatcher.id)"
        class="text-truncate"
      >
        <label
          *ngIf="dispatcher.location"
          matTooltip="{{
            dispatcher.location.address + ', ' + dispatcher.location.countryCode
          }}"
        >
          <label *ngIf="dispatcher.location.city">{{
            dispatcher.location.city + ", " + dispatcher.location.state
          }}</label>
          <label *ngIf="!dispatcher.location.city">{{
            dispatcher.location?.address
          }}</label>
        </label>
        <label
          *ngIf="dispatcher?.location?.poBox"
          matTooltip="{{ dispatcher.location.poBox }}"
          >{{ dispatcher.location.poBox }}
        </label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "DISPATCHERS.PHONE_NUMBER" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let dispatcher"
        (click)="openDialogEditDispatcher(true, dispatcher.id)"
        class="text-truncate"
      >
        <label matTooltip="{{ dispatcher.phoneNumber }}">{{
          dispatcher.phoneNumber
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef>
        {{ "DISPATCHERS.ACTIVE" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let dispatcher"
        (click)="openDialogEditDispatcher(true, dispatcher.id)"
      >
        <mat-icon *ngIf="toBool(dispatcher.isActive)" color="primary"
          >check</mat-icon
        >
        <mat-icon *ngIf="!toBool(dispatcher.isActive)" color="warn"
          >close</mat-icon
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="isAdmin">
      <th mat-header-cell *matHeaderCellDef>
        {{ "DISPATCHERS.ADMIN" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let dispatcher"
        (click)="openDialogEditDispatcher(true, dispatcher.id)"
      >
        <mat-icon *ngIf="toBool(dispatcher.isAdmin)" color="primary"
          >check</mat-icon
        >
        <mat-icon *ngIf="!toBool(dispatcher.isAdmin)" color="warn"
          >close</mat-icon
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="alignCenter">
        {{ "PAYROLL.ACTION" | translate }}
      </th>
      <td mat-cell *matCellDef="let dispatcher" class="alignCenter">
        <button
          *ngIf="userDataService.isAdminDispatcher"
          mat-button
          class="edit"
          (click)="openDialogEditDispatcher(false, dispatcher.id)"
        >
          {{ "DISPATCHERS.EDIT" | translate }}
        </button>
        <button [matMenuTriggerFor]="menu" mat-icon-button class="icon-color">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            (click)="openDialogEditDispatcher(true, dispatcher.id)"
          >
            {{ "DISPATCHERS.VIEW" | translate }}
          </button>
          <button
            *ngIf="userDataService.isAdminDispatcher"
            mat-menu-item
            (click)="openDialogEditDispatcher(false, dispatcher.id)"
          >
            {{ "DISPATCHERS.EDIT" | translate }}
          </button>
          <button
            *ngIf="userDataService.isAdminDispatcher"
            mat-menu-item
            (click)="
              openDialogChangePass(dispatcher.id, dispatcher.accountActivated)
            "
          >
            {{ "CHANGE_PASS.CHANGE_PASS" | translate }}
          </button>

          <button
            *ngIf="userDataService.isAdminDispatcher"
            mat-menu-item
            class="delete-button"
            (click)="openDialogComfirmDeleteDispatcher(dispatcher.id)"
          >
            {{ "DISPATCHERS.DELETED" | translate }}
          </button>
        </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="rowCursorPointer"
    ></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="displayedColumns"
      class="table-footer"
    ></tr>
  </table>
</div>
