<div class="wrapperTable" id="wrapperManageTables">
  <div class="addNew">
    <button mat-icon-button class="refresh" (click)="refresh()">
      <mat-icon
        matTooltip="{{ 'MANAGE.REFRESH' | translate }}"
        matTooltipPosition="below"
        >refresh</mat-icon
      >
    </button>
    <button
      *ngIf="userDataService.isAdminDispatcher"
      mat-flat-button
      class="btnDialog"
      (click)="openDialogEditTrailer(false)"
    >
      {{ "TRAILERS.ADD_TRAILER_BTN" | translate }}
    </button>
    <mat-form-field class="filterField">
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="{{ 'TRAILERS.FILTER' | translate }}"
        class="filterInput"
        [value]="searchTerm"
        autocomplete="off"
      />
      <mat-icon matPrefix class="filterIcon">search</mat-icon>
    </mat-form-field>
    <mat-slide-toggle
      [(ngModel)]="showInactive"
      (change)="refresh()"
      color="primary"
      class="activeToggle"
      matTooltip="{{ 'TRAILERS.SHOW_INACTIVE' | translate }}"
      matTooltipPosition="after"
    ></mat-slide-toggle>
  </div>

  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    id="mat-table"
    matSort
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>
        {{ "TRAILERS.ID" | translate }}
      </th>
      <td mat-cell *matCellDef="let trailer">
        {{ trailer.id }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="trailerNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "TRAILERS.TRAILER_NAME" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let trailer"
        (click)="openDialogEditTrailer(true, trailer.id)"
        class="number-truncate"
      >
        <label matTooltip="{{ trailer.trailerNumber }}">{{
          trailer.trailerNumber
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{ "TRAILERS.COUNT" | translate }} {{ getCountOfElement(type) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "TRAILERS.OWNER_NAME" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let trailer"
        (click)="openDialogEditTrailer(true, trailer.id)"
        class="text-truncate"
      >
        <label matTooltip="{{ trailer.owner }}">{{ trailer.owner }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="licensePlate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "TRAILERS.LICENSE_PLATE" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let trailer"
        (click)="openDialogEditTrailer(true, trailer.id)"
        class="number-truncate"
      >
        <label matTooltip="{{ trailer.licensePlate }}">{{
          trailer.licensePlate
        }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="equipmentTypeId">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "TRAILERS.EQ_TYPE" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let trailer"
        (click)="openDialogEditTrailer(true, trailer.id)"
      >
        <label
          *ngIf="trailer.equipmentType"
          matTooltip="{{ trailer.equipmentType.name }}"
          >{{ trailer.equipmentType.name }}
        </label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="registrationExpirationDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <label
          matTooltip="{{ 'TRAILERS.REGISTRATION_EXPIRATION_DATE' | translate }}"
        >
          {{ "TRAILERS.REGISTRATION_EXPIRATION_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let trailer"
        (click)="openDialogEditTrailer(true, trailer.id)"
      >
        <label
          matTooltip="{{
            trailer.registrationExpirationDate | tenantDate | date : 'MM/dd/y '
          }}"
        >
          {{
            trailer.registrationExpirationDate | tenantDate | date : "MM/dd/y "
          }}
        </label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="due_date_registration">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="dueMargin">
        <label matTooltip="{{ 'TRAILERS.DUE_DATE' | translate }}" class="due">
          {{ "TRAILERS.DUE_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let trailer"
        (click)="openDialogEditTrailer(true, trailer.id)"
      >
        <button
          mat-mini-fab
          *ngIf="trailer.registrationExpirationDate"
          [ngClass]="
            trailer.registrationExpirationDate + '' | colorSpanTodayDays
          "
        >
          {{ trailer.registrationExpirationDate | spanTodayDays }}
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="annualInspectionExpirationDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <label
          matTooltip="{{
            'TRAILERS.ANNUAL_INSPECTION_EXPIRATION_DATE' | translate
          }}"
        >
          {{ "TRAILERS.ANNUAL_INSPECTION_EXPIRATION_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let trailer"
        (click)="openDialogEditTrailer(true, trailer.id)"
      >
        <label
          matTooltip="{{
            trailer.annualInspectionExpirationDate
              | tenantDate
              | date : 'MM/dd/y '
          }}"
        >
          {{
            trailer.annualInspectionExpirationDate
              | tenantDate
              | date : "MM/dd/y "
          }}
        </label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="due_date_annual">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="dueMargin">
        <label matTooltip="{{ 'TRAILERS.DUE_DATE' | translate }}" class="due">
          {{ "TRAILERS.DUE_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let trailer"
        (click)="openDialogEditTrailer(true, trailer.id)"
        class="dueRight"
      >
        <button
          mat-mini-fab
          *ngIf="trailer.annualInspectionExpirationDate"
          [ngClass]="
            trailer.annualInspectionExpirationDate + '' | colorSpanTodayDays
          "
        >
          {{ trailer.annualInspectionExpirationDate | spanTodayDays }}
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="quarterlyInspectionDue">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <label matTooltip="{{ 'TRAILERS.QUARTERLY_INSPECTION' | translate }}">
          {{ "TRAILERS.QUARTERLY_INSPECTION_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let trailer"
        (click)="openDialogEditTrailer(true, trailer.id)"
      >
        <label
          matTooltip="{{
            trailer.quarterlyInspectionDue | tenantDate | date : 'MM/dd/y '
          }}"
        >
          {{ trailer.quarterlyInspectionDue | tenantDate | date : "MM/dd/y " }}
        </label>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="due_date_quarterly">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="dueMargin">
        <label matTooltip="{{ 'TRAILERS.DUE_DATE' | translate }}" class="due">
          {{ "TRAILERS.DUE_SHORT" | translate }}
        </label>
      </th>
      <td
        mat-cell
        *matCellDef="let trailer"
        (click)="openDialogEditTrailer(true, trailer.id)"
      >
        <button
          mat-mini-fab
          *ngIf="trailer.quarterlyInspectionDue"
          [ngClass]="trailer.quarterlyInspectionDue + '' | colorSpanTodayDays"
        >
          {{ trailer.quarterlyInspectionDue | spanTodayDays }}
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef>
        {{ "TRAILERS.ACTIVE" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let trailer"
        (click)="openDialogEditTrailer(true, trailer.id)"
      >
        <mat-icon *ngIf="toBool(trailer.isActive)" color="primary"
          >check</mat-icon
        >
        <mat-icon *ngIf="!toBool(trailer.isActive)" color="warn"
          >close</mat-icon
        >
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="alignCenter">
        {{ "COMMON.ACTION" | translate }}
      </th>
      <td mat-cell *matCellDef="let trailer" class="alignCenter">
        <button
          *ngIf="userDataService.isAdminDispatcher"
          mat-button
          class="edit"
          (click)="openDialogEditTrailer(false, trailer.id)"
        >
          {{ "COMMON.EDIT" | translate }}
        </button>
        <button [matMenuTriggerFor]="menu" mat-icon-button class="icon-color">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            (click)="openDialogEditTrailer(true, trailer.id)"
          >
            {{ "COMMON.VIEW" | translate }}
          </button>
          <button
            *ngIf="userDataService.isAdminDispatcher"
            mat-menu-item
            (click)="openDialogEditTrailer(false, trailer.id)"
          >
            {{ "COMMON.EDIT" | translate }}
          </button>
          <button
            *ngIf="userDataService.isAdminDispatcher"
            mat-menu-item
            class="delete-button"
            trailer
            (click)="openDialogComfirmDeleteTrailer(trailer.id)"
          >
            {{ "COMMON.DELETE" | translate }}
          </button>
        </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="rowCursorPointer"
    ></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="displayedColumns"
      class="table-footer"
    ></tr>
  </table>
</div>
