import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver-es';
import { debounce } from 'lodash-es';
import { DateTime } from 'luxon';
import { first } from 'rxjs';
import Common from '../_helpers/common';
import { AccountingModel } from '../_models/accounting/accounting';
import { Paginator } from '../_models/common/paginator';
import { SortData } from '../_models/common/sortData';
import { TableType } from '../_models/enums/tableType';
import { ToastrTranslateService } from '../_services';
import { AccountingService } from '../_services/accounting.service';
import { LocalStorageService } from '../_services/local.storage.service';
import { LocalStorageSortService } from '../_services/local.storage.sort.service';
import { AddEditDialogComponent } from './crud-accounting/add-edit-dialog/add-edit-dialog.component';
import { ComfirmDeleteDialogComponent } from './crud-accounting/comfirm-delete-dialog/comfirm-delete-dialog.component';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountingComponent implements OnInit, AfterViewInit {
  public displayedColumns: string[] = [
    'type',
    'date',
    'vendor',
    'description',
    'amount',
    'comment',
    'truck',
    'driver',
    'category',
    'status',
    'delete',
    'edit',
  ];

  totalSize = 0;
  pageSizeOptions: number[];
  pageSize;
  currentPage;
  searchTerm = '';
  sortData: SortData;
  loading = false;

  startDate = new Date(new Date().getFullYear(), 0, 1);
  endDate = Common.getDateEndOfDay(new Date());

  dataSource = new MatTableDataSource<AccountingModel>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;

  constructor(
    private localStorageService: LocalStorageService,
    private accountingService: AccountingService,
    private localStorageSortService: LocalStorageSortService,
    private toastrTranslateService: ToastrTranslateService,
    public dialog: MatDialog
  ) {}

  ngAfterViewInit() {
    this.matSort.sortChange.subscribe(() => {
      this.refresh();
      this.currentPage = 0;
    });
    setTimeout(() => {
      this.matSort.sort({
        id: this.sortData.activeColumn,
        start: this.sortData.order,
      } as MatSortable);
      this.dataSource.sort = this.matSort;
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      this.localStorageSortService.setSortData(
        TableType.AccountingMain,
        this.matSort
      );
      return item[property];
    };
  }

  ngOnInit() {
    this.sortData = this.localStorageSortService.getSortData(
      TableType.AccountingMain
    );
    this.initPaginatorOptions();

    this.onDateChange = debounce(this.onDateChange, 1000);
  }

  getAccountingData() {
    this.accountingService
      .getAccountingList(this.startDate, this.endDate, this.getPaginator())
      .pipe(first())
      .subscribe((response) => {
        if (response) {
          this.dataSource.data = response.collection;
          setTimeout(() => {
            this.paginator.length = response.count;
            this.paginator.pageIndex = this.currentPage;
          });
        }
      });
  }

  onDateChange() {
    this.refresh();
  }

  initPaginatorOptions() {
    this.pageSize = this.localStorageService.getPagginatorPageSize();
    this.pageSizeOptions = [10, 25, 50, 100];
    this.currentPage = 0;
  }

  public onChangePage(event?: PageEvent): PageEvent {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.localStorageService.setPagginatorPageSize(event.pageSize);
    this.getAccountingData();
    return event;
  }

  private getPaginator(): Paginator {
    const paginator = new Paginator();
    paginator.pageSize = this.pageSize;
    paginator.pageNumber = this.currentPage + 1;
    paginator.searchTerm = this.searchTerm;
    paginator.sortBy = this.matSort?.active;
    paginator.sortOrder = this.matSort?.direction;
    this.localStorageSortService.setSortData(
      TableType.AccountingMain,
      this.matSort
    );
    return paginator;
  }

  refresh() {
    this.getAccountingData();
  }

  search(searchTerm: string) {
    this.currentPage = 0;
    this.searchTerm = searchTerm;
    setTimeout(() => {
      this.getAccountingData();
    }, 1000);
  }

  openComfirmDeleteDialog(id: number) {
    const dialogRef = this.dialog.open(ComfirmDeleteDialogComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: id,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getAccountingData();
    });
  }

  async exportCsv() {
    this.loading = true;
    try {
      const data = await this.accountingService.getCsv(
        this.startDate,
        this.endDate,
        this.getPaginator()
      );
      const blob = new Blob([data], {
        type: 'application/vnd.ms-excel',
      });
      const fileName =
        'Accouting Export ' + DateTime.now().toFormat('MM-dd-yyyy');
      ('.xlsx');
      saveAs(blob, fileName);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.toastrTranslateService.error(error, 'COMMON.ERROR_DATA');
    }
  }

  openAddEditDialog(readOnly: boolean, id?: number) {
    const dialogRef = this.dialog.open(AddEditDialogComponent, {
      disableClose: true,
      position: { top: '20px' },
      data: {
        id,
        readOnly,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getAccountingData();
    });
  }
}
